import React from "react"
import {AnimatePresence} from "framer-motion"

const Layout = ({ children }: { children: any }) => {
  return (
  <AnimatePresence mode={"wait"}>
    { children }
  </AnimatePresence>

)}

export default Layout
